const escapeTest = /[&<>"']/
const escapeReplace = /[&<>"']/g
const escapeTestNoEncode = /[<>"']|&(?!#?\w+;)/
const escapeReplaceNoEncode = /[<>"']|&(?!#?\w+;)/g
const escapeReplacements: any = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
}

const getEscapeReplacement = (ch: string) => escapeReplacements[ch]
function escape(html: string, encode: boolean) {
  if (encode) {
    if (escapeTest.test(html)) {
      return html.replace(escapeReplace, getEscapeReplacement)
    }
  } else if (escapeTestNoEncode.test(html)) {
    return html.replace(escapeReplaceNoEncode, getEscapeReplacement)
  }

  return html
}

export function useRenderer(tips: string) {
  let id = 0
  return {
    heading(text: string, level: number, raw: any, slugger: any) {
      const _this = this as any
      if (_this.options.headerIds) {
        id++
        return (
          '<h' + level + ' id="' + _this.options.headerPrefix + slugger.slug(raw) + '" data-id="' + id + '">' + text + '</h' + level + '>\n'
        )
      }
      return '<h' + level + '>' + text + '</h' + level + '>\n'
    },
    code(code: string, escaped: any) {
      const _this = this as any
      const lang = false
      const codeSource = code
      if (_this.options.highlight) {
        const out = _this.options.highlight(code, lang)
        if (out != null && out !== code) {
          escaped = true
          code = out
        }
      }

      code = code.replace(/\n$/, '') + '\n'

      return `<div class="JCode">
      <div class="JCode-content">
        <div class="JCode-header">
          <div class="JCode-operation">
            <span class="JCode-operation-swtich"></span>
            <span class="JCode-operation-chuangkou"></span>
            <span class="JCode-operation-copy"></span>
          </div>
        </div>
        <div class="codeSource">${escape(codeSource, true)}</div>
        <pre>
          <code>${escaped ? code : escape(code, true)}</code>
        </pre>
        </div>
      <div class="JCode-placeholder">${tips}</div>
      </div>`
    },
    tablerow(content: string) {
      const parent1 = /[^%]%%%%[^%]/.test(content) ? "class='child parent1'" : ''

      const child = /[^%]%%%[^%]/.test(content) ? "class='child'" : ''
      const parent = /[^%]%%[^%]/.test(content) ? "class='parent'" : ''

      content = content.replace(/%%+/g, '')
      return '<tr ' + parent + child + parent1 + '>\n' + content + '</tr>\n'
    },
    tablecell(content: string, flags: any) {
      const type = flags.header ? 'th' : 'td'
      const tag = flags.align ? '<' + type + ' style="text-align:' + flags.align + '">' : '<' + type + '>'
      return tag + content + '</' + type + '>\n'
    },
  }
}

export const blueBlock = {
  name: 'blueBlock',
  level: 'block',
  start(src: string) {
    const reg = src.match(/:::/)
    if (reg) {
      return reg.index
    }
    return undefined
  },
  tokenizer(src: string, tokens: any): any {
    const rule = /^:::([\s\S]*?):::/
    const match = rule.exec(src)
    if (match) {
      return {
        type: 'blueBlock',
        raw: match[0],
        text: match[0].trim(),
        // @ts-ignore
        tokens: this.lexer.blockTokens(match[1]),
      }
    }
  },
  renderer(token: any): any {
    // @ts-ignore
    return `<div class="blueBlock">${this.parser.parse(token.tokens)}</div>`
  },
}
