const optionObj = {
  // 缓动
  easeInOutQuad(t: number, b: number, c: number, d: number) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
    return (-c / 2) * (--t * (t - 2) - 1) + b;
  },
  timeOut: false,
  dom: false,
  duration: 500,
} as any;
// 得到对应的元素并移动划动条
export function toTop(num = 0, ks = false, dom = false) {
  const moveDom = dom || optionObj.dom || document.documentElement;
  optionObj.dom = moveDom;
  if (!moveDom) {
    return false;
  }
  clearTimeout(optionObj.timeOut);
  if (ks) {
    moveDom.scrollTop = num;
    return false;
  }
  const b = moveDom.scrollTop;
  const d = optionObj.duration;
  const c = num - b;
  let t = 0;
  const step = 17;
  const rAF =
    window.requestAnimationFrame || ((func) => setTimeout(func, step));
  function setTo() {
    if (t < d) {
      t += step;
      t = t > d ? d : t;
      moveDom.scrollTop = optionObj.easeInOutQuad(t, b, c, d);
      optionObj.timeOut = rAF(setTo);
    }
  }
  setTo();
}

// 得到元素到顶部的距离
export function getElementToPageTop(el: any): number {
  if (!el) return 0;
  if (el.offsetParent) {
    return getElementToPageTop(el.offsetParent) + el.offsetTop;
  }
  return el.offsetTop;
}
