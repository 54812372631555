import { createApp } from '@nuxtjs/composition-api'
import Message from './index.vue'

const createInstance = (cfg) => {
  const config = cfg || {}
  const messageWrap = document.createElement('div')
  const app = createApp(Message, {
    ...config,
    remove() {
      handleRemove()
    },
  })
  document.body.appendChild(messageWrap)
  app.mount(messageWrap)

  config.close = () => {
    handleRemove()
  }

  const handleRemove = () => {
    app.unmount(messageWrap)
    document.body.removeChild(document.querySelector('.jg-message'))
  }
}

export default {
  // 纯文本消息
  info(content = '', duration = 3) {
    return createInstance({
      type: 'info',
      content,
      duration,
    })
  },
  // 成功提示
  success(content = '', duration = 3) {
    return createInstance({
      type: 'success',
      content,
      duration,
    })
  },
  // 错误提示
  error(content = '', duration = 3) {
    return createInstance({
      type: 'error',
      content,
      duration,
    })
  },
  // 警告提示
  warning(content = '', duration = 3) {
    return createInstance({
      type: 'warning',
      content,
      duration,
    })
  },
}
