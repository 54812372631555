<template>
  <Modal class="img-view" :visible="showImg" :footer="null" :closable="false" :centered="true" width="100%" @cancel="ok">
    <div class="img-warp" @click="ok">
      <div
        class="img-postion"
        @click="
          (e) => {
            e.stopPropagation()
          }
        "
      >
        <img src="@/assets/docs/icon/icon-code-close.png" class="set-icon" @click="ok" />
        <img v-if="src" class="set-img" :src="src" alt="大图" />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/common/modal/index.vue'
export default {
  components: {
    Modal,
  },
  data() {
    return {
      showImg: false,
      src: '',
    }
  },
  // type:String Number Boolean Function Object Array Symbol
  props: {
    /* src: {
      type: String,
      default() {
        return 'https://img.jiguang.cn/docs/2021/jsms/guideline/consoleguide_image/addapp1.png';
      },
    }, */
  },
  methods: {
    ok() {
      this.showImg = false
      this.src = ''
    },
    show(src) {
      this.src = src
      this.showImg = true
    },
  },
}
</script>
<style scoped lang="less">
.img-view {
  /deep/ .jg-modal-content {
    box-shadow: none;
    padding: 20px;
    background-color: transparent;
  }
  .img-warp {
    display: flex;
    justify-content: center;
    .img-postion {
      position: relative;
      .set-icon {
        position: absolute;
        right: 0px;
        top: -40px;
        cursor: pointer;
        transition: transform ease 300ms;
      }
      .set-icon:hover {
        transform: scale(1.2);
      }
      .set-img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
