import { marked } from 'marked'
import hljs from 'highlight.js/lib/common'
import { useRenderer, blueBlock } from './marked'

export function parseMD(md: string, locale: string) {
  // 代码高亮设置
  marked.setOptions({
    renderer: new marked.Renderer(),
    highlight(code: any) {
      return hljs.highlightAuto(code).value
    },
    langPrefix: 'hljs language-',
    pedantic: false,
    gfm: true,
    breaks: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
    xhtml: false,
  })
  marked.use({ extensions: [blueBlock] })
  marked.use({ renderer: useRenderer(locale) })
  const html = marked.parse(md)
  return html
}
